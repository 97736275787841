@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";


$tablet: "(max-width: 1024px)";
$desktop: "(min-width: 1024px)";
$mobile: "(max-width: 768px)";

body{
  font-size: 12px;
}


.thumbnail {
  margin-bottom: 30px;

  > img, a > img {
    //width: 142px;
    //height: 80px;
    padding: 10px;
  }
}
.badge{
  background-color: #ff001f !important;
  margin-left: 5px;
}
.navbar {
  margin-bottom: 0px !important;
}

.form-control{
  font-size:11px !important;
}

.table-bordered {
  border: 1px solid $table-border-color;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 1px solid $table-border-color;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
      text-align: center;
      background-color: #f0f8ff;
    }
  }
}

.jumbotron {
  background-color: #fbfbfb !important;
}

.profile-background {
  //background-color: #e97d00;
  //padding: 60px;

  .gradiant-background {


    @media #{$mobile} {
      background: rgba(255,255,255,0);
      background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 17%, rgba(255,255,255,1) 100%);
      background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(17%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
      background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 17%, rgba(255,255,255,1) 100%);
      background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 17%, rgba(255,255,255,1) 100%);
      background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 17%, rgba(255,255,255,1) 100%);
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 17%, rgba(255,255,255,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
    }

    background: rgba(255,255,255,0);
    background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(255,255,255,0)), color-stop(35%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1)));
    background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%);
    background: -o-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%);
    background: -ms-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=0 );
  }
  .white-background {
    padding-top: 80px;
    //margin-top: 5px;
    margin-bottom: 1px;
    min-height: 528px;
  }
  .profile-head {
    img {
      padding: 10px 20px 10px 20px;
      background-color: #ffffff;
      border-radius: 4px;
    }

    h5 {
      font-weight: bold;
      //font-size: 30px;
    }

    .description {
      //background-color: #ffffff;
      //border-radius: 4px;
      padding: 15px;

      @media #{$mobile} {
        margin: 20px 0px;
      }
      ul{
        li{
          list-style-type: none;

          a{
            color:#000000;
          }
        }
      }
    }
  }
}
.red-background {
  background-color: #b40006;
  //padding: 60px;

  .gradiant-background {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b40006+0,ffffff+100&1+0,0+100 */
    background: -moz-linear-gradient(top,  rgba(180,0,6,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(180,0,6,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(180,0,6,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b40006', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
  }
  .white-background {
    background-color: #ffffff;
    padding-top: 30px;
    min-height: 528px;
  }
  .profile-head {
    img {
      padding: 10px 20px 10px 20px;
      background-color: #ffffff;
      border-radius: 4px;
    }

    h5 {
      font-weight: bold;
      //font-size: 30px;
    }

    .description {
      border-radius: 4px;
      padding: 15px;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.3+0,0.3+100 */
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4dffffff', endColorstr='#4dffffff', GradientType=0); /* IE6-9 */

      .inner {
        opacity: 1;
      }
    }
  }
}
.blue-background {
  background-color: #1071b4;
  //padding: 60px;

  .gradiant-background {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#1071b4+0,ffffff+100&1+0,0+100 */
    background: -moz-linear-gradient(top,  rgba(16,113,180,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(16,113,180,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(16,113,180,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1071b4', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */

  }
  .white-background {
    background-color: #ffffff;
    padding-top: 30px;
    min-height: 528px;
  }
  .profile-head {
    img {
      padding: 10px 20px 10px 20px;
      background-color: #ffffff;
      border-radius: 4px;
    }

    h5 {
      font-weight: bold;
      //font-size: 30px;
    }

    .description {
      border-radius: 4px;
      padding: 15px;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.3+0,0.3+100 */
      background: -moz-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.3) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#4dffffff', endColorstr='#4dffffff', GradientType=0); /* IE6-9 */

      .inner {
        opacity: 1;
      }
    }
  }
}

footer {
  text-align: center;
  p {
    padding: 0px 15px;
  }
  img{
    margin: 0 10px;
    width: 100px;
  }
}

.navbar-default{
  background-color: #ffffff;
}

.navbar-brand {
  img {
    display: block;
    width: 130px;
    margin-top: -15px;
  }
}

.user-login{
  margin-top: 95px;
  padding: 30px;
  border: 1px solid #31b0d5;
  border-radius: 5px;

  h4{
    font-weight: 600;
    //text-decoration: underline;
  }
}

.welcome{
  min-height: 100vh;
}


$red: #ee001d;
$green: #008000;

.error_tr{
  background-color: $red;
  color: #ffffff;
}

.nav-pills li {
    background-color: #337ab7;
    border-color: #122b40;
    border-radius: 4px;
}

.credit-hold {
    background-color: #b40006 !important;
    border-color: #6b0508 !important;
}

.nav-pills li a {
    color:#ffffff;
}

tr th{
  background-color: #bfbfbf;
}